<section class="h-screen flex items-center">
  <div class="max-w-lg mx-auto p-4 flex flex-col items-center gap-2 w-full">
    <img
      src="https://superlikers-themes.s3.amazonaws.com/modelo_para_ganar/v4/logo-primario.png"
      alt="Modelo para ganar Logo" />
    <h1 class="font-bold text-lg">¡Bienvenido/a Modelo Para Ganar!</h1>
    <p class="text-base">Ingresa tus datos:</p>
    <form
      class="flex flex-col gap-4 w-full"
      autocomplete="off"
      [formGroup]="form"
      (ngSubmit)="submit()">
      @if (error()) {
        <div class="text-theme-red text-sm text-center">{{ error() }}</div>
      }

      <div class="p-6.5 flex flex-col gap-6">
        @for (control of controls; track control.name) {
          <ng-container
            [ngComponentOutlet]="controlResolver.resolve(control, form) | async"
            [ngComponentOutletInjector]="control.name | controlInjector: control">
          </ng-container>
        }
      </div>

      <a
        routerLink="/password/new"
        class="cursor-pointer self-end text-theme-aquamarine text-sm transition-colors duration-300 hover:text-theme-aquamarine/80">
        ¿Olvidaste tu contraseña?
      </a>

      <button
        class="button1"
        [disabled]="loading()"
        type="submit">
        Inciar Sesión
      </button>
    </form>

    <a
      (click)="openHelpModal()"
      class="cursor-pointer text-theme-aquamarine flex gap-2 items-center font-light text-sm mt-16 transition-colors duration-300 hover:text-theme-aquamarine/80">
      ¿Necesitas ayuda con tu registro?
      <img
        class="w-8"
        src="https://modeloparaganar.s3.us-east-2.amazonaws.com/layout/ICO_MAIL.png"
        alt="help icon" />
    </a>
  </div>
</section>
